exports.components = {
  "component---gatsby-plugin-cart-redirect-src-cart-redirect-page-tsx": () => import("./../../../../gatsby-plugin-cart-redirect/src/CartRedirectPage.tsx" /* webpackChunkName: "component---gatsby-plugin-cart-redirect-src-cart-redirect-page-tsx" */),
  "component---gatsby-plugin-index-src-404-404-tsx": () => import("./../../../../gatsby-plugin-index/src/404/404.tsx" /* webpackChunkName: "component---gatsby-plugin-index-src-404-404-tsx" */),
  "component---gatsby-plugin-index-src-index-index-tsx": () => import("./../../../../gatsby-plugin-index/src/index/Index.tsx" /* webpackChunkName: "component---gatsby-plugin-index-src-index-index-tsx" */),
  "component---gatsby-plugin-index-src-page-index-page-index-tsx": () => import("./../../../../gatsby-plugin-index/src/page-index/PageIndex.tsx" /* webpackChunkName: "component---gatsby-plugin-index-src-page-index-page-index-tsx" */),
  "component---src-components-common-wix-error-page-wix-error-page-tsx": () => import("./../../../src/components/common/wixErrorPage/WixErrorPage.tsx" /* webpackChunkName: "component---src-components-common-wix-error-page-wix-error-page-tsx" */),
  "component---src-components-webs-shutdown-page-webs-shutdown-page-tsx": () => import("./../../../src/components/websShutdownPage/WebsShutdownPage.tsx" /* webpackChunkName: "component---src-components-webs-shutdown-page-webs-shutdown-page-tsx" */),
  "component---src-components-wix-cancel-page-wix-cancel-page-tsx": () => import("./../../../src/components/wixCancelPage/WixCancelPage.tsx" /* webpackChunkName: "component---src-components-wix-cancel-page-wix-cancel-page-tsx" */),
  "component---src-components-wix-new-plans-page-wix-new-anonymous-plans-page-tsx": () => import("./../../../src/components/wixNewPlansPage/WixNewAnonymousPlansPage.tsx" /* webpackChunkName: "component---src-components-wix-new-plans-page-wix-new-anonymous-plans-page-tsx" */),
  "component---src-components-wix-new-plans-page-wix-new-change-plans-page-tsx": () => import("./../../../src/components/wixNewPlansPage/WixNewChangePlansPage.tsx" /* webpackChunkName: "component---src-components-wix-new-plans-page-wix-new-change-plans-page-tsx" */),
  "component---src-components-wix-new-plans-page-wix-new-initial-upgrade-plans-page-tsx": () => import("./../../../src/components/wixNewPlansPage/WixNewInitialUpgradePlansPage.tsx" /* webpackChunkName: "component---src-components-wix-new-plans-page-wix-new-initial-upgrade-plans-page-tsx" */),
  "component---src-components-wix-new-plans-page-wix-new-second-upgrade-plans-page-tsx": () => import("./../../../src/components/wixNewPlansPage/WixNewSecondUpgradePlansPage.tsx" /* webpackChunkName: "component---src-components-wix-new-plans-page-wix-new-second-upgrade-plans-page-tsx" */),
  "component---src-components-wix-redirect-page-wix-redirect-page-tsx": () => import("./../../../src/components/wixRedirectPage/WixRedirectPage.tsx" /* webpackChunkName: "component---src-components-wix-redirect-page-wix-redirect-page-tsx" */),
  "component---src-components-wix-site-selector-page-wix-site-selector-page-tsx": () => import("./../../../src/components/wixSiteSelectorPage/WixSiteSelectorPage.tsx" /* webpackChunkName: "component---src-components-wix-site-selector-page-wix-site-selector-page-tsx" */),
  "component---src-components-wix-terms-and-conditions-page-wix-terms-and-conditions-page-tsx": () => import("./../../../src/components/wixTermsAndConditionsPage/WixTermsAndConditionsPage.tsx" /* webpackChunkName: "component---src-components-wix-terms-and-conditions-page-wix-terms-and-conditions-page-tsx" */),
  "component---src-components-wix-websites-page-wix-pdp-c-new-design-websites-page-tsx": () => import("./../../../src/components/wixWebsitesPage/WixPdpCNewDesignWebsitesPage.tsx" /* webpackChunkName: "component---src-components-wix-websites-page-wix-pdp-c-new-design-websites-page-tsx" */),
  "component---src-components-wix-websites-page-wix-pdp-c-websites-page-tsx": () => import("./../../../src/components/wixWebsitesPage/WixPdpCWebsitesPage.tsx" /* webpackChunkName: "component---src-components-wix-websites-page-wix-pdp-c-websites-page-tsx" */)
}

